<template>
  <div v-click-outside="hide" class="locale-switcher position-relative">
    <div @click="visible = !visible">
      <div
        class="d-flex justify-content-center align-items-center locale-icon bg-white"
      >
        <img
          v-if="$i18n.locale === 'en'"
          src="@/assets/flag/usa-flag.png"
          width="22"
          height="22"
        />

        <img
          v-if="$i18n.locale === 'ch'"
          src="@/assets/flag/china-flag.png"
          width="22"
        />
      </div>
    </div>
    <div
      v-if="visible"
      class="switcher-list position-absolute d-flex flex-column bg-white"
    >
      <div
        @click="doChangeLanguage('en')"
        class="d-flex aligh-items-center align-items-center switcher-list--item"
      >
        <div
          class="d-flex justify-content-center align-items-center locale-icon bg-white"
          :class="{ 'switcher-list--item--disable': $i18n.locale !== 'en' }"
        >
          <img src="@/assets/flag/usa-flag.png" width="22" height="22" />
        </div>

        <span>English (US)</span>
      </div>
      <div
        class="mt-3 d-flex aligh-items-center align-items-center switcher-list--item"
        @click="doChangeLanguage('ch')"
      >
        <div
          class="d-flex justify-content-center align-items-center locale-icon bg-white"
          :class="{ 'switcher-list--item--disable': $i18n.locale !== 'ch' }"
        >
          <img src="@/assets/flag/china-flag.png" width="22" height="22" />
        </div>

        <span>简体中文</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  data() {
    return {
      visible: false
    }
  },

  mounted() {
    this.$i18n.locale = localStorage.getItem('lang') || 'en'
  },
  methods: {
    doChangeLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.visible = false
    },
    hide() {
      this.visible = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>
.switcher-list {
  width: 160px;
  top: 40px;
  right: 0;
  border-radius: 6px;
  padding: 10px;
  color: #127568;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.locale-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  border-style: solid;
  border-color: #19a693;
  box-sizing: content-box;
}

.switcher-list--item {
  gap: 10px;
  cursor: pointer;
}

.switcher-list--item--disable {
  border-style: none;
  width: 33px;
}
</style>
