<template>
  <div>
    <div class="vector-1">
      <img src="@/assets/facility/facility-vector.png" alt="" />
    </div>
    <div class="vector-2">
      <img src="@/assets/facility/facility-vector.png" alt="" />
    </div>
    <h2 class="mt-4 mt-lg-5 mb-4 mb-lg-3 title text-center">
      {{ $t('facility.title') }}
    </h2>
    <div class="container">
      <div class="row mb-lg-5 facility-order">
        <div class="col-12 col-lg-8 d-flex align-items-center order-2">
          <div
            :class="device ? ' wow fadeInLeft' : ''"
            class="facility-text text-first"
            data-wow-duration="1s"
          >
            <h4 class="sub-title underline">{{ $t('facility.warehouse') }}</h4>
            <div class="text" :class="{ active: boxFull }">
              <p>
                {{ $t('facility.content-1-1') }}
              </p>

              <p>
                {{ $t('facility.content-1-2') }}
              </p>

              <p>
                {{ $t('facility.content-1-3') }}
              </p>

              <p>
                {{ $t('facility.content-1-4') }}
              </p>
              </p>
            </div>
            <div class="text-center mt-4 d-lg-none">
              <div @click="boxFull = !boxFull">
                <span :class="{ 'd-none': boxFull }">See More</span>
                <div>
                  <i class="fa-solid fa-angle-down" v-if="!boxFull"></i
                  ><i class="fa-solid fa-angle-up" v-else></i>
                </div>
              </div>
            </div>
            <div class="blur-text" v-if="!boxFull"></div>
          </div>
        </div>
        <div class="col-12 col-lg-4 order-1">
          <vueper-slides
            :progress="device"
            :bullets="false"
            fixed-height="495px"
            :class="device ? 'wow fadeInRight' : ''"
            class="facility"
            data-wow-duration="1s"
          >
            <template #arrow-left>
              <div class="button-slide">
                <i class="fa-solid fa-chevron-left"></i>
              </div>
            </template>

            <template #arrow-right>
              <div class="button-slide">
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </template>
            <vueper-slide
              :key="1"
              :image="require('@/assets/facility/warehouse/1.jpg')"
            />
            <vueper-slide
              :key="2"
              :image="require('@/assets/facility/warehouse/2.jpg')"
            />
            <vueper-slide
              :key="3"
              :image="require('@/assets/facility/warehouse/3.jpg')"
            />
          </vueper-slides>
        </div>
      </div>
      <div class="row mt-lg-5">
        <div class="col-lg-4 col-12">
          <vueper-slides
            :progress="device"
            :bullets="false"
            fixed-height="495px"
            class="facility"
            :class="device ? 'wow fadeInLeft' : ''"
            data-wow-duration="1s"
          >
            <template #arrow-left>
              <div class="button-slide">
                <i class="fa-solid fa-chevron-left"></i>
              </div>
            </template>

            <template #arrow-right>
              <div class="button-slide">
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </template>
            <vueper-slide
              :key="1"
              :image="require('@/assets/facility/private-wharf/1.jpg')"
            />
            <vueper-slide
              :key="2"
              :image="require('@/assets/facility/private-wharf/2.jpg')"
            />
          </vueper-slides>
        </div>
        <div class="col-lg-8 col-12 d-flex align-items-center">
          <div
            class="facility-text right"
            :class="device ? ' wow fadeInRight' : ''"
            data-wow-duration="1s"
          >
            <h4 class="sub-title underline">{{ $t('facility.private-wharf') }}</h4>
            <p>
              {{ $t('facility.content-2-1') }}
            </p>

            <p>
              {{ $t('facility.content-2-2') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      boxFull: false
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
    padding: 0 15px;
  }
}
</style>
