<template>
  <div>
    <h2 class="mb-4 mb-lg-3 mt-lg-1 title text-center">
      {{ $t('about.title') }}
    </h2>
    <div class="container-about">
      <div class="logo d-none d-lg-block" :class="device ? 'wow fadeIn' : ''">
        <img class="mw-100" src="@/assets/logo.png" alt="" />
      </div>
      <div class="sub-container">
        <div
          class="about-box box-text-1 text-grey text bg-grey"
          :class="device ? 'wow fadeInLeft' : ''"
        >
          <h3 class="sub-title underline text-dark">
            {{ $t('about.our-profile') }}
          </h3>
          <div class="box box-profile" :class="{ active: boxOneFull }">
            <p>
              {{ $t('about.content-1-1') }}
            </p>

            <p v-html="$t('about.content-1-2')" />

            <p v-html="$t('about.content-1-3')" />
          </div>
          <div class="blur-text" v-if="!boxOneFull"></div>
          <div class="text-center mt-4 d-lg-none see-more">
            <div @click="boxOneFull = !boxOneFull">
              <span :class="{ 'd-none': boxOneFull }">See More</span>
              <div>
                <i class="fa-solid fa-angle-down" v-if="!boxOneFull"></i
                ><i class="fa-solid fa-angle-up" v-else></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="about-box box-image-1"
          :class="device ? ' wow fadeInRight' : ''"
        >
          <img
            class="w-100 d-none d-lg-block"
            src="@/assets/about/profile2x.png"
            alt=""
          />
          <img
            class="w-100 d-lg-none"
            src="@/assets/about/profile-mobile.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="container-about green">
      <div class="logo d-none d-lg-block" :class="device ? 'wow fadeIn' : ''">
        <img class="mw-100" src="@/assets/logo.png" alt="" />
      </div>
      <div class="sub-container">
        <div
          class="about-box text-right box-image-2"
          :class="device ? ' wow fadeInLeft' : ''"
        >
          <img
            class="mw-100 d-none d-lg-block"
            src="@/assets/about/strength2x.png"
            alt=""
          />
          <img
            class="w-100 d-lg-none"
            src="@/assets/about/strength-mobile.png"
            alt=""
          />
        </div>
        <div
          class="about-box text text-white bg-green box-2 d-flex align-items-center"
          :class="device ? ' wow fadeInRight' : ''"
        >
          <div>
            <h3 class="sub-title underline">{{ $t('about.our-strength') }}</h3>
            <div class="box" :class="{ active: boxTwoFull }">
              <p>
                {{ $t('about.content-2-1') }}
              </p>
              <p>
                {{ $t('about.content-2-2') }}
              </p>

              <p>
                {{ $t('about.content-2-3') }}
              </p>
              <p>
                {{ $t('about.content-2-4') }}
              </p>

              <p>
                {{ $t('about.content-2-5') }}
              </p>
              <p>{{ $t('about.content-2-6') }}</p>
              <p v-html="$t('about.content-2-7')"></p>
            </div>
            <div class="blur-text" v-if="!boxTwoFull"></div>
            <div class="text-center mt-4 d-lg-none see-more">
              <div @click="boxTwoFull = !boxTwoFull">
                <span :class="{ 'd-none': boxTwoFull }">See More</span>
                <div>
                  <i class="fa-solid fa-angle-down" v-if="!boxTwoFull"></i
                  ><i class="fa-solid fa-angle-up" v-else></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxOneFull: false,
      boxTwoFull: false
    }
  }
}
</script>

<style></style>
