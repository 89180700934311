<template>
  <div class="container">
    <div>
      <div class="row">
        <div class="col-12 main">
          <Flicking
            ref="flicking"
            :options="{
              bounce: '20%',
              panelsPerView: 1,
              easing: (x) => x,
              inputType: ['touch', 'mouse']
            }"
            :plugins="plugins"
            @move-end="(e) => (e.direction != null ? moveEnd(e) : '')"
          >
            <div class="panel" v-for="(value, index) in product" :key="index">
              <div class="bg-product">
                <div
                  class="row"
                  :class="index + 1 != activePage ? 'opacity-0' : ''"
                >
                  <div class="col-12 text-center">
                    <div
                      class="product text-right animate__animated animate__fast"
                      :class="index + 1 == activePage ? bindClass : ''"
                    >
                      <div>
                        <img
                          :src="value.image"
                          class="mw-100 img-product"
                          alt=""
                        />
                      </div>
                      <div :class="`effect effect-${index + 1}`">
                        <img :src="value.effect" alt="" />
                      </div>
                      <div
                        class="desc animate__animated animate__delay-1s"
                        :class="
                          index + 1 == activePage
                            ? `animate__fadeIn desc-${index + 1}`
                            : ` desc-${index + 1}`
                        "
                      >
                        <img :src="value.imgDesc" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      data-parallaxify-background-range="100"
                      class="animate__animated animate__delay-0-4s position-relative"
                      :class="index + 1 == activePage ? bindClass : ''"
                    >
                      <h2 class="mt-3">
                        {{ $t(value.title) }}
                      </h2>
                      <div
                        class="box"
                        :class="boxFull ? `full box-1` : ` box-1`"
                      >
                        <div v-html="$t(value.description)"></div>
                        <div style="margin-top: 25px">
                          <div
                            v-for="(data, num) in value.link"
                            :key="num"
                            class="row text-center mb-4"
                          >
                            <!--
                            <div class="col-12">
                              <a :href="data.link" target="_blank">
                                {{ $t('product.download') }}
                              </a>
                            </div>
                            -->
                          </div>
                        </div>
                        <div v-if="!boxFull" class="blur-text"></div>
                      </div>

                      <div class="text-center text-white mt-4 d-lg-none">
                        <div @click="boxFull = !boxFull">
                          <span :class="{ 'd-none': boxFull }">See More</span>
                          <div>
                            <i
                              class="fa-solid fa-angle-down"
                              v-if="!boxFull"
                            ></i
                            ><i class="fa-solid fa-angle-up" v-else></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Flicking>
          <div class="arrow-sl d-flex d-lg-none">
            <div
              class="arr-left mr-4"
              :class="{ lastColor: activePage == 1 }"
              @click="activePage > 1 ? moveSlide(activePage - 2) : ''"
            >
              <i class="fa-solid fa-chevron-left"></i>
            </div>
            <div
              class="arr-right"
              :class="{ lastColor: activePage == 4 }"
              @click="activePage < 4 ? moveSlide(activePage) : ''"
            >
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </div>
          <div class="pagination">
            <div class="page" v-for="(value, index) in product" :key="index">
              <div
                class="dot"
                @click="moveSlide(index)"
                :class="{ active: index + 1 == activePage }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Flicking } from '@egjs/vue-flicking'
import { Parallax } from '@egjs/flicking-plugins'

export default {
  computed: {
    process() {
      return (this.activePage / this.product.length) * 100
    }
  },
  components: {
    Flicking
  },
  data() {
    return {
      typeClick: '',
      boxFull: false,
      plugins: [new Parallax('img', 4)],
      indicator: null,
      activePage: 1,
      bindClass: 'animate__fadeInRight',
      product: [
        {
          link: [
            {
              title: 'Thai Hom Mali Rice',
              link: '/document/Thai-Hom-Mali-White-Rice.pdf'
            }
          ],
          image: require('@/assets/product/1/product.png'),
          effect: require('@/assets/product/1/effect.png'),
          imgDesc: require('@/assets/product/1/description.png'),
          title: 'product.content-1.title',
          description: 'product.content-1.description'
        },
        {
          link: [
            {
              title: 'Thai White Rice 100% Grade A',
              link: '/document/Thai-White-Rice.pdf'
            }
          ],
          image: require('@/assets/product/2/product.png'),
          effect: require('@/assets/product/2/effect.png'),
          imgDesc: require('@/assets/product/2/description.png'),
          title: 'product.content-2.title',
          description: 'product.content-2.description'
        },
        {
          link: [
            {
              title: 'Thai Parboiled Rice',
              link: '/document/Thai-Parboiled-Rice.pdf'
            }
          ],
          image: require('@/assets/product/3/product.png'),
          effect: require('@/assets/product/3/effect.png'),
          imgDesc: require('@/assets/product/3/description.png'),
          title: 'product.content-3.title',
          description: 'product.content-3.description'
        },
        {
          link: [
            {
              title: 'Thai Glutinous Rice',
              link: '/document/Thai-White-Glutinous.pdf'
            }
          ],
          image: require('@/assets/product/4/product.png'),
          effect: require('@/assets/product/4/effect.png'),
          imgDesc: require('@/assets/product/4/description.png'),
          title: 'product.content-4.title',
          description: 'product.content-4.description'
        }
      ]
    }
  },
  methods: {
    moveSlide(e) {
      this.typeClick = ''
      console.log(e, this.$refs.flicking.moveTo)
      this.$refs.flicking.moveTo(e)
    },
    moveEnd(e) {
      // this.boxFull = false;
      console.log(this.$refs.flicking)

      if (e.currentTarget.index > -1) {
        this.activePage = -1
        if (e.direction == 'NEXT') {
          this.bindClass = 'animate__fadeInRight'
          setTimeout(() => {
            this.activePage = e.currentTarget.index + 1
          }, 50)
        } else if (e.direction == 'PREV') {
          this.bindClass = 'animate__fadeInLeft'
          setTimeout(() => {
            this.activePage = e.currentTarget.index + 1
          }, 50)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.product {
  position: relative;
  .effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .desc-1 {
    top: 20px;
    left: -33px;
  }
  .desc-2 {
    top: 47px;
    left: -74px;
  }
  .desc-3 {
    top: 39px;
    left: -128px;
  }
  .desc-4 {
    top: 25px;
    left: -200px;
  }
}
.see-product {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  z-index: 1;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  /* identical to box height, or 0% */

  text-align: center;

  color: #c0d1cf;
}
.img-product {
  transform: translate(-0%) translate(0px) !important;
}
.animate__animated {
  &.animate__delay-0-4s {
    -webkit-animation-delay: calc(1s * 0.4);
    animation-delay: calc(1s * 12);
    -webkit-animation-delay: calc(var(--animate-delay) * 0.4);
    animation-delay: calc(var(--animate-delay) * 0.4);
  }

  &.animate__delay-1s {
    -webkit-animation-delay: calc(1s * 1.1);
    animation-delay: calc(1s * 12);
    -webkit-animation-delay: calc(var(--animate-delay) * 1.1);
    animation-delay: calc(var(--animate-delay) * 1.1);
  }
}
</style>
