<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="bg-contact">
          <div class="row">
            <div class="col-lg-6">
              <div class="box-contact">
                <h2 class="mt-0 title text-white underline">
                  {{ $t('contact.title') }}
                </h2>
                <p class="text-second d-flex flex-wrap" v-html="$t('contact.description')" /></p>
              </div>
            </div>
            <div class="col-lg-6">
              <form @submit.prevent="sendMail" ref="form">
                <div class="form-contact">
                  <div class="row form-group" :class="{ validate: valid.name }">
                    <div class="col-lg-3">
                      <p>{{ $t('contact.contact-name') }}</p>
                    </div>
                    <div class="col-lg-9 pr-lg-0">
                      <input
                        v-model="name"
                        type="text"
                        name="name"
                        class="form-control"
                      />
                      <div class="input-valid">Please fill name</div>
                    </div>
                  </div>
                  <div class="row form-group" :class="{ validate: valid.tel }">
                    <div class="col-lg-3">
                      <p>{{ $t('contact.phone') }}</p>
                    </div>
                    <div class="col-lg-9 pr-lg-0">
                      <input
                        v-model="tel"
                        name="tel"
                        type="text"
                        class="form-control"
                      />
                      <div class="input-valid">Please fill Phone</div>
                    </div>
                  </div>
                  <div
                    class="row form-group"
                    :class="{ validate: valid.email }"
                  >
                    <div class="col-lg-3">
                      <p>{{ $t('contact.email') }}</p>
                    </div>
                    <div class="col-lg-9 pr-lg-0">
                      <input
                        name="email"
                        v-model="email"
                        type="text"
                        class="form-control"
                      />
                      <div class="input-valid">Please fill Email</div>
                    </div>
                  </div>
                  <div
                    class="row form-group"
                    :class="{ validate: valid.message }"
                  >
                    <div class="col-lg-3">
                      <p>{{ $t('contact.message') }}</p>
                    </div>
                    <div class="col-lg-9 pr-lg-0">
                      <textarea
                        rows="4"
                        name="message"
                        v-model="message"
                        class="form-control"
                      ></textarea>
                      <div class="input-valid">Please fill Message</div>
                    </div>
                  </div>
                  <div
                    class="row form-group"
                    :class="{ validate: valid.chcekBox }"
                  >
                    <div class="col-3"></div>
                    <div class="col-8 col-lg-9 pr-lg-0">
                      <label class="container">
                        {{ $t('contact.accepted') }}
                        <input type="checkbox" v-model="chcekBox" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3 d-none d-lg-block"></div>
                    <div class="col-12 col-lg-9 text-center">
                      <button class="btn btn-submit" type="submit">{{  $t('contact.send') }}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="contact-detail d-none d-lg-block">
          <div class="row align-items-start">
            <div class="col-lg-6 after-bar" style="padding-right: 4%">
              <table>
                <tr>
                  <td style="vertical-align: top">
                    <h4 class="sub-title mr-3 mt-0" style="width: 164px">
                      {{ $t('contact.our-factory') }}
                    </h4>
                  </td>
                  <td>
                    <p style="padding-right: 22%">
                      {{ $t('contact.factory-address') }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-6 d-flex" style="padding: 0 4%">
              <table>
                <tr>
                  <td style="vertical-align: top">
                    <h4 class="sub-title mt-0" style="width: 164px">
                      {{ $t('contact.our-office') }}
                    </h4>
                  </td>
                  <td>
                    <p class="mb-4">
                      {{ $t('contact.office-address') }}
                    </p>
                    <p class="mb-2">
                      <a href="tel:266-6000-10"
                        >{{ $t('contact.tel') }} : (662) 266-6000-10</a
                      >
                    </p>
                    <p class="mb-2">
                      <a href="">{{ $t('contact.fax') }} : (662) 266-6060-1</a>
                    </p>
                    <p>
                      <a href="mailto: marketing@thaihua.co.th"
                        >{{ $t('contact.email') }} : marketing@thaihua.co.th</a
                      >
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="back-to-top">
            <a href="#header" v-smooth-scroll>
              <button type="button" class="btn btn-info btn-to-top">
                <i class="fa-solid fa-angle-up"></i></button
            ></a>
          </div>
        </div>
        <div class="contact-detail d-lg-none">
          <div class="row align-items-start">
            <div class="col-12 after-bar">
              <h4 class="sub-title mt-lg-0 mt-4">
                {{ $t('contact.our-factory') }}
              </h4>
              <p>
                {{ $t('contact.factory-address') }}
              </p>
            </div>
            <div class="col-12">
              <h4 class="sub-title mt-0">{{ $t('contact.our-office') }}</h4>
              <p class="mb-lg-4 mb-3">
                {{ $t('contact.office-address') }}
              </p>
              <p class="mb-lg-2 mb-1">
                <a href="tel:266-6000-10"
                  >{{ $t('contact.tel') }} : (662) 266-6000-10</a
                >
              </p>
              <p class="mb-lg-2 mb-1">
                <a href="">{{ $t('contact.fax') }} : (662) 266-6060-1</a>
              </p>
              <p>
                <a href="mailto: marketing@thaihua.co.th"
                  >{{ $t('contact.email') }} : marketing@thaihua.co.th</a
                >
              </p>
            </div>
          </div>
          <div class="back-to-top">
            <a href="#header" v-smooth-scroll>
              <button type="button" class="btn btn-info btn-to-top">
                <i class="fa-solid fa-angle-up"></i></button
            ></a>
          </div>
        </div>
        <b-modal v-model="modalShow" centered hide-header hide-footer>
          <div class="modal-text">
            <h3>Sent Successfully!</h3>
            <p>
              Thank you for contacting us,<br />
              Your submission has been received.
            </p>
            <button @click="modalShow = false" class="btn btn-submit mb-0">
              Done
            </button>
          </div></b-modal
        >
      </div>
    </div>
  </div>
</template>

<script>
const sgMail = require('@sendgrid/mail')
import emailjs from 'emailjs-com'
export default {
  data() {
    return {
      loading: 1,
      modalShow: false,
      name: '',
      tel: '',
      email: '',
      message: '',
      chcekBox: false,
      valid: {
        name: false,
        tel: false,
        email: false,
        message: false,
        chcekBox: false
      }
    }
  },
  watch: {
    name() {
      if (this.loading > 1) {
        if (!this.name) {
          this.valid.name = true
        } else {
          this.valid.name = false
        }
      }
    },
    tel() {
      if (this.loading > 1) {
        if (!this.tel) {
          this.valid.tel = true
        } else {
          this.valid.tel = false
        }
      }
    },
    email() {
      if (this.loading > 1) {
        if (!this.email) {
          this.valid.email = true
        } else {
          this.valid.email = false
        }
      }
    },
    message() {
      if (this.loading > 1) {
        if (!this.message) {
          this.valid.message = true
        } else {
          this.valid.message = false
        }
      }
    },
    chcekBox() {
      if (this.loading > 1) {
        if (!this.chcekBox) {
          this.valid.chcekBox = true
        } else {
          this.valid.chcekBox = false
        }
      }
    }
  },
  methods: {
    validateFunction() {
      if (
        !this.name ||
        !this.tel ||
        !this.email ||
        !this.message ||
        !this.chcekBox
      ) {
        if (!this.name) {
          this.valid.name = true
        } else {
          this.valid.name = false
        }
        if (!this.tel) {
          this.valid.tel = true
        } else {
          this.valid.tel = false
        }
        if (!this.email) {
          this.valid.email = true
        } else {
          this.valid.email = false
        }
        if (!this.message) {
          this.valid.message = true
        } else {
          this.valid.message = false
        }
        if (!this.chcekBox) {
          this.valid.chcekBox = true
        } else {
          this.valid.chcekBox = false
        }
        this.loading = 2
        return false
      } else {
        return true
      }
    },
    sendMail(e) {
      console.log('sssss')
      if (this.validateFunction()) {
        try {
          console.log(e.target)
          emailjs
            .sendForm(
              'service_dlnsi9a',
              process.env.VUE_APP_MAIL_TEMPLATE,
              this.$refs.form,
              '4aYNshfXUkSs5THcC'
            )
            .then((e) => {
              console.log(e.status == 200)
              if (e.status == 200) {
                this.loading = 1
                this.name = ''
                this.tel = ''
                this.email = ''
                this.message = ''
                this.chcekBox = false
                this.modalShow = true
              }
            })
        } catch (error) {
          this.loading = 1
          console.log({ error })
        }
      }

      // console.log(process.env.VUE_APP_MAIL_KEY);
      // sgMail.setApiKey(process.env.VUE_APP_MAIL_KEY);
      // const msg = {
      //   to: "marketing@thaihua.co.th", // Change to your recipient
      //   from: this.email.name, // Change to your verified sender
      //   subject: "CONTACT",
      //   text: this.email.message,
      //   html: `<table width="100%" cellpadding="0" cellspacing="0"><tbody><tr><td><table style="width:100%;margin:0;padding:0" width="100%" cellpadding="0" cellspacing="0"><tbody><tr><td style="width:100%;margin:0;padding:0" width="100%"><table style="width:570px;margin:0 auto;border:0px solid #e7eaec;background-color:#fefefe;border-top:0" width="570" cellpadding="0" cellspacing="0"><tbody></tbody></table><table style="width:570px;margin:0 auto;padding:0;border:1px solid #e7eaec;background-color:#fefefe;padding-bottom:80px;" width="570" cellpadding="0" cellspacing="0"><tbody><tr><td style="padding:35px;"><h2 style="margin-bottom: 40px;">Contact</h2><p style="margin-bottom: 20px;">Name: ${this.email.name}</p>p style="margin-bottom: 20px;">Phone: ${this.email.tel}</p><p style="margin-bottom: 20px;">Email: ${this.email.email}</p><p style="margin-bottom: 20px;">Message: ${this.email.Message}</p></td></tr></tbody></table></td></tr><tr><td><table style="width:570px;margin:0 auto;padding:0" width="570" cellpadding="0" cellspacing="0"><tbody><tr><td style="padding:35px"></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table>`,
      // };

      // sgMail
      //   .send(msg)
      //   .then(() => {
      //     this.modalShow = true;
      //     console.log("Email sent");
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: inherit;
}
</style>
