<template>
  <div>
    <h2 class="mb-4 mb-lg-3 mt-4 title text-center">
      {{ $t('our-group.title') }}
    </h2>
    <div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="box-group box-1 h-100"
            :class="device ? 'wow fadeInLeft' : ''"
          >
            <div class="image-group">
              <img
                src="@/assets/group/Sethiwan Group.png"
                class="mw-100 d-none d-lg-block"
                alt=""
              />
              <img
                src="@/assets/group/Sethiwan Group mobile.png"
                class="w-100 d-lg-none"
                alt=""
              />
            </div>
            <div class="inside-right">
              <div style="position: relative">
                <h4 class="sub-title text-white underline mb-4">
                  Sethiwan Group
                </h4>
                <div class="box-1" :class="{ full: boxOneFull }">
                  <p>
                    Sethiwan Group have been at the frontier of catering the
                    expat’s residential and commercial needs for the past 30
                    years. We offer luxury apartments and rental office spaces
                    in Bangkok CBD locations.
                  </p>
                  <h5 class="mt-3 mb-2 text-white">Contact</h5>
                  <p>
                    <a href="tel:+6622666000"
                      ><img
                        src="@/assets/icon/telephone.svg"
                        class="mr-2"
                        alt=""
                      />
                      +662 266 6000</a
                    >
                  </p>
                  <p>
                    <a
                      class="underline"
                      target="_blank"
                      href="http://www.sethiwan.com"
                      ><img
                        src="@/assets/icon/message.svg"
                        class="mr-2"
                        alt=""
                      />
                      www.sethiwan.com</a
                    >
                  </p>
                </div>
                <div v-if="!boxOneFull" class="blur-text"></div>
              </div>

              <div class="text-center text-white mt-1 d-lg-none">
                <div @click="boxOneFull = !boxOneFull">
                  <span :class="{ 'd-none': boxOneFull }">See More</span>
                  <div>
                    <i class="fa-solid fa-angle-down" v-if="!boxOneFull"></i
                    ><i class="fa-solid fa-angle-up" v-else></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="box-group box-2 h-100"
            :class="device ? 'wow fadeInRight' : ''"
          >
            <div class="image-group">
              <img
                src="@/assets/group/Golf Clubs.png"
                class="mw-100 d-none d-lg-block"
                alt=""
              />
              <img
                src="@/assets/group/Golf Clubs mobile.png"
                class="w-100 d-lg-none"
                alt=""
              />
            </div>
            <div class="inside-right">
              <div class="position-relative">
                <h4 class="sub-title underline mb-4">Golf Clubs</h4>
                <div class="box-2" :class="{ full: boxTwoFull }">
                  <p>
                    We are partners in developing successful and prestigious
                    golf courses in Thailand, namely
                  </p>
                  <h5 class="mt-3 mb-2">Contact</h5>
                  <div class="row">
                    <div class="col-lg-5">
                      <p>Ayodhaya Link</p>
                    </div>
                    <div class="col-lg-7">
                      <p>
                        <a href="tel:+6635257999"
                          ><img
                            src="@/assets/icon/telephone.svg"
                            class="mr-2 icon"
                            alt=""
                          />
                          +663 525 7999</a
                        >
                      </p>
                      <p>
                        <a
                          class="underline"
                          target="_blank"
                          href="https://www.ayodhyalinks.com/"
                          ><img
                            src="@/assets/icon/message.svg"
                            class="mr-2 icon"
                            alt=""
                          />
                          www.ayodhyalinks.com</a
                        >
                      </p>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <div class="row">
                    <div class="col-lg-5">
                      <p>Rachakram Golf Club</p>
                    </div>
                    <div class="col-lg-7">
                      <p>
                        <a href="tel:+66235367060"
                          ><img
                            src="@/assets/icon/telephone.svg"
                            class="mr-2 icon"
                            alt=""
                          />
                          +662 35 367 060-61</a
                        >
                      </p>
                      <p>
                        <a
                          class="underline"
                          target="_blank"
                          href="https://www.rachakramgolfclub.com/"
                          ><img
                            src="@/assets/icon/message.svg"
                            class="mr-2 icon"
                            alt=""
                          />
                          www.rachakramgolfclub.com</a
                        >
                      </p>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <div class="row">
                    <div class="col-12">
                      <p>Royal Bangpa-in (coming soon)</p>
                    </div>
                  </div>
                </div>
                <div v-if="!boxTwoFull" class="blur-text"></div>
              </div>

              <div class="text-center mt-1 d-lg-none">
                <div @click="boxTwoFull = !boxTwoFull">
                  <span :class="{ 'd-none': boxTwoFull }">See More</span>
                  <div>
                    <i class="fa-solid fa-angle-down" v-if="!boxTwoFull"></i
                    ><i class="fa-solid fa-angle-up" v-else></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row order-div">
        <div class="col-lg-6 order-2">
          <div
            class="box-group box-3 h-100"
            :class="device ? 'wow fadeInLeft' : ''"
          >
            <div class="image-group">
              <img
                src="@/assets/group/Thai Fa Power.png"
                class="mw-100 d-none d-lg-block"
                alt=""
              />
              <img
                src="@/assets/group/Thai Fa Power mobile.png"
                class="w-100 d-lg-none"
                alt=""
              />
            </div>
            <div class="order-1 inside-right">
              <div class="position-relative">
                <h4 class="sub-title underline mb-4">Thai Fa Power</h4>
                <div class="box-3" :class="{ full: boxThreeFull }">
                  <p>
                    We have expanded into the field of alternative energy,
                    specifically solar energy. We invested in 6 solar farms and
                    power plants; and became a strategic partner of SPCG Public
                    Company Limited.
                  </p>
                  <h5 class="mt-3 mb-2">Contact</h5>
                  <p>
                    <a href="tel:+6622666000"
                      ><img
                        src="@/assets/icon/telephone.svg"
                        class="mr-2 icon"
                        alt=""
                      />
                      +662 266 6000</a
                    >
                  </p>
                  <p>
                    <a
                      class="underline"
                      target="_blank"
                      href="https://www.spcg.co.th/"
                      ><img
                        src="@/assets/icon/message.svg"
                        class="mr-2 icon"
                        alt=""
                      />
                      www.spcg.co.th</a
                    >
                  </p>
                </div>
                <div v-if="!boxThreeFull" class="blur-text"></div>
              </div>

              <div class="text-center mt-1 d-lg-none">
                <div @click="boxThreeFull = !boxThreeFull">
                  <span :class="{ 'd-none': boxThreeFull }">See More</span>
                  <div>
                    <i class="fa-solid fa-angle-down" v-if="!boxThreeFull"></i
                    ><i class="fa-solid fa-angle-up" v-else></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="box-group box-4 h-100"
            :class="device ? 'wow fadeInRight' : ''"
          >
            <div class="image-group">
              <img
                src="@/assets/group/ASL Securities.png"
                class="mw-100 d-none d-lg-block"
                alt=""
              />
              <img
                src="@/assets/group/ASL Securities mobile.png"
                class="w-100 d-lg-none"
                alt=""
              />
            </div>
            <div class="inside-right">
              <div class="position-relative">
                <h4 class="sub-title text-white underline mb-4">
                  ASL Securities
                </h4>
                <div class="box-4" :class="{ full: boxFourFull }">
                  <p>
                    ASL Securities is a visionary in online stock exchange
                    platform, service with experienced team managers in the
                    Capital Market. The service provide strong research teams,
                    cutting edge computer systems and trading programs.
                  </p>
                  <h5 class="mt-3 mb-2 text-white">Contact</h5>
                  <p>
                    <a href="tel:+6625081567"
                      ><img
                        src="@/assets/icon/telephone.svg"
                        class="mr-2"
                        alt=""
                      />
                      +662 508 1567</a
                    >
                  </p>
                  <p>
                    <a
                      class="underline"
                      target="_blank"
                      href="https://www.aslsecurities.com/"
                      ><img
                        src="@/assets/icon/message.svg"
                        class="mr-2"
                        alt=""
                      />
                      www.aslsecurities.com</a
                    >
                  </p>
                </div>
                <div v-if="!boxFourFull" class="blur-text"></div>
              </div>

              <div class="text-center text-white mt-4 d-lg-none">
                <div @click="boxFourFull = !boxFourFull">
                  <span :class="{ 'd-none': boxFourFull }">See More</span>
                  <div>
                    <i class="fa-solid fa-angle-down" v-if="!boxFourFull"></i
                    ><i class="fa-solid fa-angle-up" v-else></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxOneFull: false,
      boxTwoFull: false,
      boxThreeFull: false,
      boxFourFull: false
    }
  }
}
</script>

<style></style>
