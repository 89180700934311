<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-11 d-flex justify-content-between">
          <div class="logo mt-lg-2 mr-5">
            <a href="/"> <img src="@/assets/logo.png" alt="" /></a>
          </div>
          <nav class="w-100 d-none d-xl-flex">
            <ul>
              <li>
                <a href="#header" v-smooth-scroll>
                  {{ $t('header.home') }}
                </a>
              </li>
              <li>
                <a href="#product" v-smooth-scroll>
                  {{ $t('header.products') }}
                </a>
              </li>
              <li>
                <a href="#about-us" v-smooth-scroll>
                  {{ $t('header.about-us') }}
                </a>
              </li>
              <li>
                <a href="#processing" v-smooth-scroll>
                  {{ $t('header.processing') }}
                </a>
              </li>
              <li>
                <a href="#facility" v-smooth-scroll>
                  {{ $t('header.facility') }}
                </a>
              </li>
              <li>
                <a href="#gallery" v-smooth-scroll>
                  {{ $t('header.gallery') }}
                </a>
              </li>
              <li>
                <a href="#our-group" v-smooth-scroll>
                  {{ $t('header.our-group') }}
                </a>
              </li>
              <li>
                <a href="#contact" v-smooth-scroll>
                  {{ $t('header.contact') }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div
          class="col-1 d-flex align-items-center justify-content-end"
          style="height: 60px"
        >
          <a
            href="#contact"
            @click="menuActive = false"
            v-smooth-scroll
            class="text-white d-none d-lg-block"
            ><img src="@/assets/message.png" alt=""
          /></a>
          <div class="d-xl-none text-white ml-3">
            <div class="" style="font-size: 24px">
              <i class="fa-solid fa-bars" @click="menuActive = true"></i>
            </div>
          </div>
          <LocaleSwitcher class="ml-3" />
        </div>
      </div>
    </div>
    <div class="menu-mobile" :class="{ menuActive: menuActive }">
      <div
        class="py-3 px-4 text-white text-right w-100"
        style="font-size: 24px"
      >
        <i class="fa-solid fa-xmark" @click="menuActive = false"></i>
      </div>
      <div class="h-100 d-flex align-items-center pb-5">
        <ul class="pb-5">
          <li>
            <a href="#header" @click="menuActive = false" v-smooth-scroll>{{
              $t('header.home')
            }}</a>
          </li>
          <li>
            <a href="#product" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.products') }}
            </a>
          </li>
          <li>
            <a
              href="#about-us"
              id="to-about"
              @click="menuActive = false"
              v-smooth-scroll
            >
              {{ $t('header.about-us') }}
            </a>
          </li>
          <li>
            <a href="#processing" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.processing') }}
            </a>
          </li>
          <li>
            <a href="#facility" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.facility') }}
            </a>
          </li>
          <li>
            <a href="#gallery" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.gallery') }}
            </a>
          </li>
          <li>
            <a href="#our-group" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.our-group') }}
            </a>
          </li>
          <li>
            <a href="#contact" @click="menuActive = false" v-smooth-scroll>
              {{ $t('header.contact') }}
            </a>
          </li>
          <li>
            <a
              href="#contact"
              @click="menuActive = false"
              v-smooth-scroll
              class="text-white"
              ><img src="@/assets/message.png" alt=""
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher'
export default {
  components: {
    LocaleSwitcher
  },
  data() {
    return {
      menuActive: false
    }
  }
}
</script>
