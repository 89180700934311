<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mb-4 mb-lg-0">
          <div class="d-lg-flex align-items-start pr-lg-5 box-footer">
            <div class="col-2 col-lg-2 mx-auto text-right px-2">
              <img class="mw-100 mr-lg-3 mb-3" src="@/assets/logo.png" alt="" />
            </div>
            <div class="content-footer">
              <p class="text-second">
                © Thai Hua (2511) Co.,Ltd. 2016. All rights reserved.
              </p>
              <p class="text-second mb-0">
                {{ $t('footer.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 d-lg-none">
          <div class="col-5 mx-auto">
            <hr />
          </div>
        </div>
        <div class="col-lg-4">
          <nav>
            <ul>
              <li>
                <a href="#product" v-smooth-scroll>
                  {{ $t('footer.products') }}
                </a>
              </li>
              <li>
                <a href="#about-us" v-smooth-scroll>
                  {{ $t('footer.about-us') }}</a
                >
              </li>

              <li>
                <a href="#processing" v-smooth-scroll>
                  {{ $t('footer.processing') }}</a
                >
              </li>
              <li>
                <a href="#facility" v-smooth-scroll>
                  {{ $t('footer.facility') }}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#gallery" v-smooth-scroll>
                  {{ $t('footer.gallery') }}
                </a>
              </li>
              <li>
                <a href="#our-group" v-smooth-scroll>
                  {{ $t('footer.our-group') }}
                </a>
              </li>
              <li>
                <a href="#contact" v-smooth-scroll>
                  {{ $t('footer.contact') }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style></style>
