<template>
  <div class="cover-image" id="header">
    <video
      autoplay
      muted
      loop
      playsinline
      width="100%"
      @canplay="(e) => (device ? timeUpdate(e) : '')"
      @play="startClip()"
    >
      <source src="@/assets/vdo-banner.mp4" type="video/mp4" />
    </video>
    <div class="content">
      <div
        class="head-text animate__animated"
        :class="
          device && fadeIn
            ? 'animate__fadeIn animate__slower'
            : device && !fadeIn
            ? 'animate__fadeOut'
            : ''
        "
      >
        {{ $t('banner.title') }}
      </div>
      <div
        class="sub-text animate__animated"
        :class="
          device && fadeIn
            ? 'animate__fadeIn animate__slower'
            : device && !fadeIn
            ? 'animate__fadeOut'
            : ''
        "
      >
        {{ $t('banner.description') }}
      </div>
      <!-- <div
        class="sub-text animate__animated "
        :class="
          device && fadeIn
            ? 'animate__fadeIn animate__slower'
            : device && !fadeIn
            ? 'animate__fadeOut'
            : ''
        "
      >
        <h5>{{ $t('banner.description') }}</h5>
      </div> -->
      <div
        class="sub-content animate__animated"
        :class="
          device && fadeIn
            ? 'animate__fadeIn animate__slower'
            : device && !fadeIn
            ? 'animate__fadeOut'
            : ''
        "
      >
        <div class="mb-lg-4 edit-margin">
          {{ $t('banner.meta.meta-1-1') }}
          <br v-if="!device" />
          {{ $t('banner.meta.meta-1-2') }}
        </div>
        <div>
          {{ $t('banner.meta.meta-2-1') }}
          <span v-if="!device">
            {{ $t('banner.meta.meta-3-1') }}<br v-if="!device" />
            {{ $t('banner.meta.meta-3-2') }}</span
          >
        </div>
        <div v-if="device">
          {{ $t('banner.meta.meta-3-1') }} {{ $t('banner.meta.meta-3-2') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fadeIn: true,
      interVal: null
    }
  },

  // mounted() {
  //   !this.device ? this.fadeIn == true : "";
  // },
  methods: {
    timeUpdate(e) {
      this.fadeIn = true
      // setTimeout(() => {
      //   this.fadeIn = false;
      // }, 19000);
    },
    startClip() {}
  }
}
</script>

<style></style>
