var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-none d-lg-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 main"},[_c('Flicking',{ref:"flicking",attrs:{"bound":"{true}","options":{
            align: 'prev',
            panelsPerView: 1,
            easing: (x) => x,
            inputType: ['mouse']
          },"plugins":_vm.plugins},on:{"move-start":(e) => _vm.moveEnd(e)}},_vm._l((_vm.product),function(value,index){return _c('div',{key:index,staticClass:"panel"},[_c('kinesis-container',[_c('div',{staticClass:"bg-product"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 text-center"},[_c('div',{staticClass:"product text-center animate__animated animate__fast",class:index + 1 == _vm.activePage ? _vm.bindClass : ''},[_c('div',[_c('img',{staticClass:"mw-100 img-product",attrs:{"src":value.image,"alt":""}})]),_c('div',{class:` effect effect-${index + 1}`,attrs:{"data-parallaxify-background-range":"100"}},[_c('kinesis-element',{attrs:{"strength":50}},[_c('img',{attrs:{"src":value.effect,"alt":""}})])],1),_c('div',{staticClass:"desc animate__animated animate__delay-1s",class:index + 1 == _vm.activePage
                            ? `animate__fadeIn desc-${index + 1}`
                            : ` desc-${index + 1}`},[_c('img',{attrs:{"src":value.imgDesc,"alt":""}})])])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"animate__animated animate__delay-0-4s",class:index + 1 == _vm.activePage ? _vm.bindClass : ''},[_c('h2',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.$t(value.title))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(value.description))}}),_c('div',{staticStyle:{"margin-top":"25px"}},_vm._l((value.link),function(data,num){return _c('div',{key:num,staticClass:"d-flex"})}),0)])])])])])],1)}),0),_c('div',{staticClass:"arrow-sl d-none d-lg-flex"},[_c('div',{staticClass:"arr-left mr-4",class:{ lastColor: _vm.activePage == 1 },on:{"click":function($event){_vm.activePage > 1 ? _vm.prev() : ''}}},[_c('i',{staticClass:"fa-solid fa-chevron-left"})]),_c('div',{staticClass:"arr-right",class:{ lastColor: _vm.activePage == 4 },on:{"click":function($event){_vm.activePage < 4 ? _vm.next() : ''}}},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]),_c('div',{staticClass:"pagination"},_vm._l((_vm.product),function(value,index){return _c('div',{key:index,staticClass:"page"},[_c('div',{staticClass:"dot",class:{ active: index + 1 == _vm.activePage },on:{"click":function($event){return _vm.moveSlide(index)}}})])}),0),_vm._m(0),_c('div',{staticClass:"process-bar"},[_c('div',{staticClass:"processing",style:(`width:${_vm.process}%`)})])],1)])]),_c('div',{staticClass:"d-lg-none"},[_c('ProductMobile')],1),_c('li',{staticStyle:{"opacity":"0"}},[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"href":"#header","id":"to-header"}},[_vm._v("Processing")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"see-product"},[_c('p',{staticClass:"mb-4 font-scroll"},[_vm._v("Scroll Down To See Product")]),_c('div',{staticClass:"mb-2"},[_c('img',{attrs:{"src":require("@/assets/mouse.png"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/arrow-down.png"),"alt":""}})])])
}]

export { render, staticRenderFns }