<template>
  <DefaultTemplate>
    <!-- <Banner /> -->
    <section id="banner">
      <VideoBanner />
    </section>
    <section id="product">
      <Product />
    </section>
    <section id="about-us">
      <About />
    </section>
    <section id="processing">
      <Processing />
    </section>
    <section id="facility"><Facility /></section>
    <section id="gallery">
      <Gallery />
    </section>
    <section id="our-group">
      <OurGroup />
    </section>
    <section id="contact">
      <Contact />
    </section>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Banner from "@/components/Banner";
import VideoBanner from "@/components/VideoBanner";
import Product from "@/components/Product";
import About from "@/components/About";
import Processing from "@/components/Processing";
import Facility from "@/components/Facility";
import Gallery from "@/components/Gallery";
import OurGroup from "@/components/OurGroup";
import Contact from "@/components/Contact";
export default {
  name: "Home",
  components: {
    DefaultTemplate,
    Banner,
    VideoBanner,
    Product,
    About,
    Processing,
    Facility,
    Gallery,
    OurGroup,
    Contact,
  },
};
</script>
