var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 main"},[_c('Flicking',{ref:"flicking",attrs:{"options":{
            bounce: '20%',
            panelsPerView: 1,
            easing: (x) => x,
            inputType: ['touch', 'mouse']
          },"plugins":_vm.plugins},on:{"move-end":(e) => (e.direction != null ? _vm.moveEnd(e) : '')}},_vm._l((_vm.product),function(value,index){return _c('div',{key:index,staticClass:"panel"},[_c('div',{staticClass:"bg-product"},[_c('div',{staticClass:"row",class:index + 1 != _vm.activePage ? 'opacity-0' : ''},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"product text-right animate__animated animate__fast",class:index + 1 == _vm.activePage ? _vm.bindClass : ''},[_c('div',[_c('img',{staticClass:"mw-100 img-product",attrs:{"src":value.image,"alt":""}})]),_c('div',{class:`effect effect-${index + 1}`},[_c('img',{attrs:{"src":value.effect,"alt":""}})]),_c('div',{staticClass:"desc animate__animated animate__delay-1s",class:index + 1 == _vm.activePage
                          ? `animate__fadeIn desc-${index + 1}`
                          : ` desc-${index + 1}`},[_c('img',{attrs:{"src":value.imgDesc,"alt":""}})])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"animate__animated animate__delay-0-4s position-relative",class:index + 1 == _vm.activePage ? _vm.bindClass : '',attrs:{"data-parallaxify-background-range":"100"}},[_c('h2',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t(value.title))+" ")]),_c('div',{staticClass:"box",class:_vm.boxFull ? `full box-1` : ` box-1`},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(value.description))}}),_c('div',{staticStyle:{"margin-top":"25px"}},_vm._l((value.link),function(data,num){return _c('div',{key:num,staticClass:"row text-center mb-4"})}),0),(!_vm.boxFull)?_c('div',{staticClass:"blur-text"}):_vm._e()]),_c('div',{staticClass:"text-center text-white mt-4 d-lg-none"},[_c('div',{on:{"click":function($event){_vm.boxFull = !_vm.boxFull}}},[_c('span',{class:{ 'd-none': _vm.boxFull }},[_vm._v("See More")]),_c('div',[(!_vm.boxFull)?_c('i',{staticClass:"fa-solid fa-angle-down"}):_c('i',{staticClass:"fa-solid fa-angle-up"})])])])])])])])])}),0),_c('div',{staticClass:"arrow-sl d-flex d-lg-none"},[_c('div',{staticClass:"arr-left mr-4",class:{ lastColor: _vm.activePage == 1 },on:{"click":function($event){_vm.activePage > 1 ? _vm.moveSlide(_vm.activePage - 2) : ''}}},[_c('i',{staticClass:"fa-solid fa-chevron-left"})]),_c('div',{staticClass:"arr-right",class:{ lastColor: _vm.activePage == 4 },on:{"click":function($event){_vm.activePage < 4 ? _vm.moveSlide(_vm.activePage) : ''}}},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]),_c('div',{staticClass:"pagination"},_vm._l((_vm.product),function(value,index){return _c('div',{key:index,staticClass:"page"},[_c('div',{staticClass:"dot",class:{ active: index + 1 == _vm.activePage },on:{"click":function($event){return _vm.moveSlide(index)}}})])}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }