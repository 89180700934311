<template>
  <div class="pt-4">
    <h2 class="mt-0 mb-4 mb-lg-3 title text-center">
      {{ $t('gallery.title') }}
    </h2>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-6 pr-1 pr-lg-3">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG020.jpg')"
                >
                  <img src="@/assets/gallery/1x2.png" class="w-100" alt="" />
                </a>
              </div>
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG013.jpg')"
                >
                  <img src="@/assets/gallery/2x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-6 pl-1 pl-lg-3">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG012.jpg')"
                >
                  <img
                    src="@/assets/gallery/3x2.png"
                    class="w-100 mh-100"
                    alt=""
                  />
                </a>
              </div>
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/4New.png')"
                >
                  <img src="@/assets/gallery/4x3.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-12">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG021.jpg')"
                >
                  <img src="@/assets/gallery/8x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-12">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/5New.png')"
                >
                  <img src="@/assets/gallery/5New.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-6 pr-1 pr-lg-3">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG025.jpg')"
                >
                  <img src="@/assets/gallery/6x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-6 pl-1 pl-lg-3">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG016.jpg')"
                >
                  <img src="@/assets/gallery/7x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-6 pr-1 pr-lg-3">
              <div
                class="gallery-box mb-lg-4 mb-2"
                :class="device ? 'wow fadeInUp' : ''"
              >
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG019.jpg')"
                >
                  <img src="@/assets/gallery/9x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
            <div class="col-6 pl-1 pl-lg-3">
              <div class="gallery-box" :class="device ? 'wow fadeInUp' : ''">
                <a
                  data-fancybox="gallery"
                  :href="require('@/assets/gallery/Gallery/IMG026.jpg')"
                >
                  <div class="backdrop">
                    <div>{{ $t('gallery.view-more') }}</div>
                  </div>
                  <img src="@/assets/gallery/10x2.png" class="w-100" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="d-none">
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/DSC08629.jpg')"
          >
            <img
              src="@/assets/gallery/view more/DSC08629.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/IMG014.jpg')"
          >
            <img
              src="@/assets/gallery/view more/IMG014.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/IMG017.jpg')"
          >
            <img
              src="@/assets/gallery/view more/IMG017.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/IMG022.jpg')"
          >
            <img
              src="@/assets/gallery/view more/IMG022.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/IMG027.jpg')"
          >
            <img
              src="@/assets/gallery/view more/IMG027.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="require('@/assets/gallery/view more/polishing.jpg')"
          >
            <img
              src="@/assets/gallery/view more/polishing.jpg"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="
              require('@/assets/gallery/view more/vlcsnap-2016-03-22-13h37m10s987.png')
            "
          >
            <img
              src="@/assets/gallery/view more/vlcsnap-2016-03-22-13h37m10s987.png"
              class="w-100"
              alt=""
          /></a>
          <a
            data-fancybox="gallery"
            :href="
              require('@/assets/gallery/view more/vlcsnap-2016-03-22-13.png')
            "
          >
            <img
              src="@/assets/gallery/view more/vlcsnap-2016-03-22-13.png"
              class="w-100"
              alt=""
          /></a>
        </div>
      </div>
      <div class="row mb-4 d-none d-lg-flex">
        <div class="col-12">
          <vueper-slides :bullets="false" fixed-height="495px" class="gallery">
            <vueper-slide
              :key="1"
              :image="require('@/assets/gallery/slide1.png')"
            />
            <vueper-slide
              :key="2"
              :image="require('@/assets/gallery/slide2.png')"
            />
            <template #arrow-left>
              <div class="button-slide white">
                <i class="fa-solid fa-chevron-left"></i>
              </div>
            </template>

            <template #arrow-right>
              <div class="button-slide white">
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </template>
          </vueper-slides>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
export default {
  components: { VueperSlides, VueperSlide }
}
</script>

<style></style>
