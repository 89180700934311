var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cover-image",attrs:{"id":"header"}},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","width":"100%"},domProps:{"muted":true},on:{"canplay":(e) => (_vm.device ? _vm.timeUpdate(e) : ''),"play":function($event){return _vm.startClip()}}},[_c('source',{attrs:{"src":require("@/assets/vdo-banner.mp4"),"type":"video/mp4"}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"head-text animate__animated",class:_vm.device && _vm.fadeIn
          ? 'animate__fadeIn animate__slower'
          : _vm.device && !_vm.fadeIn
          ? 'animate__fadeOut'
          : ''},[_vm._v(" "+_vm._s(_vm.$t('banner.title'))+" ")]),_c('div',{staticClass:"sub-text animate__animated",class:_vm.device && _vm.fadeIn
          ? 'animate__fadeIn animate__slower'
          : _vm.device && !_vm.fadeIn
          ? 'animate__fadeOut'
          : ''},[_vm._v(" "+_vm._s(_vm.$t('banner.description'))+" ")]),_c('div',{staticClass:"sub-content animate__animated",class:_vm.device && _vm.fadeIn
          ? 'animate__fadeIn animate__slower'
          : _vm.device && !_vm.fadeIn
          ? 'animate__fadeOut'
          : ''},[_c('div',{staticClass:"mb-lg-4 edit-margin"},[_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-1-1'))+" "),(!_vm.device)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-1-2'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-2-1'))+" "),(!_vm.device)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-3-1'))),(!_vm.device)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-3-2')))]):_vm._e()]),(_vm.device)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('banner.meta.meta-3-1'))+" "+_vm._s(_vm.$t('banner.meta.meta-3-2'))+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }