<template>
  <div class="cover-image" id="header">
    <div class="image-background">
      <img
        src="@/assets/header2x.jpg"
        class="mw-100 d-none d-lg-block"
        alt=""
      />
      <img src="@/assets/header-mobile.png" class="w-100 d-lg-none" alt="" />
    </div>
    <div class="content">
      <div
        class="head-text animate__slower animate__animated"
        :class="device ? 'animate__fadeIn' : ''"
      >
        Thai Hua<br v-if="!device" />
      </div>
      <div
        class="sub-text animate__slower animate__animated d-none d-lg-block"
        :class="device ? 'animate__fadeIn' : ''"
      >
        “Thailand’s leading rice exporter”
      </div>
      <div
        class="sub-text animate__slower animate__animated d-lg-none"
        :class="device ? 'animate__fadeIn' : ''"
      >
        <h4>“Thailand’s leading</h4>
        <h5>rice exporter”</h5>
      </div>
      <div class="sub-content">
        <div class="mb-3 mb-lg-4">
          Thai Hua (2511) Co., Ltd. is a well-known, reputable
          <br v-if="!device" />and highly respectable thai rice export for over
          50 years.
        </div>
        <div>
          Thai rice exporter for over 50 years. As one of Thailand’s Top 5
          largest <br v-if="device" />
          rice exporters, we pride ourselves in supplying the highest quality
          Thai<br v-if="device" />
          rice to the global market with competitive price and reliability.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
