var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"d-none d-lg-block"},[_c('h2',{staticClass:"mb-4 title text-center text-white"},[_vm._v(" "+_vm._s(_vm.$t('processing.title'))+" ")]),_c('div',{staticClass:"box-process"},_vm._l((_vm.data),function(value,index){return _c('div',{key:index,staticClass:"timeline",class:_vm.activeBlock == value.href ? 'active' : ''},[_c('div',{staticClass:"picture",attrs:{"id":value.href},on:{"click":function($event){_vm.activeBlock = value.href}}},[_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('div',{staticClass:"container-circle"},[_c('div',{staticClass:"sub-dot"}),_c('div',{staticClass:"dot-timeline",class:_vm.activeBlock != value.href ? 'green-toggle-outer-circle' : ''}),_c('div',{staticClass:"image"},[_c('img',{staticClass:"m-100 h-100",attrs:{"src":value.image,"alt":""}})])])]),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"text"},[_c('h4',{staticClass:"sub-title text-white underline"},[_vm._v(" "+_vm._s(_vm.$t(value.title))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(value.description))}})])])}),0),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"d-lg-none mobile"},[_c('h2',{staticClass:"mt-0 title mb-5 text-center text-white"},[_vm._v(" "+_vm._s(_vm.$t('processing.title'))+" ")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"slide-container"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"next"},[_c('i',{staticClass:"fa-solid fa-chevron-left",on:{"click":function($event){return _vm.prev()}}})]),_c('div',{staticClass:"index-active"},[_vm._v(_vm._s(_vm.activePage)+"/"+_vm._s(_vm.data.length))]),_c('div',{staticClass:"prev"},[_c('i',{staticClass:"fa-solid fa-chevron-right",on:{"click":function($event){return _vm.next()}}})])])]),_c('Flicking',{ref:"flicking",attrs:{"options":{
              align: 'prev',
              panelsPerView: 1,
              easing: (x) => x
            }},on:{"changed":(e) => _vm.moveEnd(e)}},_vm._l((_vm.data),function(value,index){return _c('div',{key:index,staticClass:"panel"},[_c('div',{staticClass:"image"},[_c('div',{staticClass:"border-image"},[_c('div',{staticClass:"pic"},[_c('img',{staticClass:"m-100 h-100",attrs:{"src":value.image,"alt":""}})])])]),_c('div',{staticClass:"content"},[_c('h2',[_vm._v(_vm._s(_vm.$t(value.title)))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(value.description))}})])])}),0)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-1"},[_c('img',{attrs:{"src":require("@/assets/Frame-left1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-2"},[_c('img',{attrs:{"src":require("@/assets/frame-right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-3"},[_c('img',{attrs:{"src":require("@/assets/Frame-left2.png"),"alt":""}})])
}]

export { render, staticRenderFns }