<template>
  <div class="pt-5">
    <div class="d-none d-lg-block">
      <h2 class="mb-4 title text-center text-white">
        {{ $t('processing.title') }}
      </h2>
      <div class="box-process">
        <div
          class="timeline"
          :class="activeBlock == value.href ? 'active' : ''"
          v-for="(value, index) in data"
          :key="index"
        >
          <div
            class="picture"
            @click="activeBlock = value.href"
            :id="value.href"
          >
            <div class="w-100 d-flex justify-content-center">
              <div class="container-circle">
                <div class="sub-dot"></div>
                <div
                  class="dot-timeline"
                  :class="
                    activeBlock != value.href ? 'green-toggle-outer-circle' : ''
                  "
                ></div>

                <div class="image">
                  <img class="m-100 h-100" :src="value.image" alt="" />
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="text">
            <h4 class="sub-title text-white underline">
              {{ $t(value.title) }}
            </h4>
            <p v-html="$t(value.description)"></p>
          </div>
        </div>
      </div>
      <div class="vector-1">
        <img src="@/assets/Frame-left1.png" alt="" />
      </div>
      <div class="vector-2">
        <img src="@/assets/frame-right.png" alt="" />
      </div>
      <div class="vector-3">
        <img src="@/assets/Frame-left2.png" alt="" />
      </div>
    </div>
    <div class="d-lg-none mobile">
      <h2 class="mt-0 title mb-5 text-center text-white">
        {{ $t('processing.title') }}
      </h2>
      <div class="container">
        <div class="slide-container">
          <div class="control">
            <div class="box">
              <div class="next">
                <i class="fa-solid fa-chevron-left" @click="prev()"></i>
              </div>
              <div class="index-active">{{ activePage }}/{{ data.length }}</div>
              <div class="prev">
                <i class="fa-solid fa-chevron-right" @click="next()"></i>
              </div>
            </div>
          </div>
          <Flicking
            ref="flicking"
            :options="{
              align: 'prev',
              panelsPerView: 1,
              easing: (x) => x
            }"
            @changed="(e) => moveEnd(e)"
          >
            <div class="panel" v-for="(value, index) in data" :key="index">
              <div class="image">
                <div class="border-image">
                  <div class="pic">
                    <img class="m-100 h-100" :src="value.image" alt="" />
                  </div>
                </div>
              </div>
              <div class="content">
                <h2>{{ $t(value.title) }}</h2>
                <p v-html="$t(value.description)"></p>
              </div>
            </div>
          </Flicking>
        </div>
      </div>
    </div>
    <!-- <svg
                class="dot-svg"
                :class="activeBlock != value.href ? 'active' : ''"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="76px"
                height="76px"
                viewBox="0 0 127.32 127.32"
                style="enable-background: new 0 0 127.32 127.32"
                xml:space="preserve"
              >
                <g class="green-toggle-opacity">
                  <circle
                    class="green-toggle-outer-circle"
                    cx="63.66"
                    cy="63.66"
                    r="63.66"
                  ></circle>
                </g>
                <path
                  class="green-toggle-inner-circle"
                  d="M120.8,63.66c0,31.56-25.58,57.14-57.14,57.14S6.52,95.22,6.52,63.66S32.1,6.52,63.66,6.52
	S120.8,32.1,120.8,63.66z"
                ></path>
              </svg> -->
  </div>
</template>

<script>
import { Flicking } from '@egjs/vue-flicking'
export default {
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    moveEnd(e) {
      if (e.direction == 'NEXT') {
        this.activePage = e.index + 1
      } else {
        this.activePage = e.index + 1
      }
    },
    elementInViewport(el) {
      var top = el.offsetTop - 90
      var height = el.offsetHeight

      while (el.offsetParent) {
        el = el.offsetParent
        top += el.offsetTop
      }
      this.page.window = window.pageYOffset + window.innerHeight
      this.page.top = top + height
      return (
        top >= window.pageYOffset &&
        top + height <= window.pageYOffset + window.innerHeight
      )
    },
    handleScroll() {
      const elementsInViewArray = this.data.map((number) => {
        const el = document.getElementById(number.href)

        if (this.elementInViewport(el)) {
          return number.href
        }
      })
      if (typeof elementsInViewArray.find((number) => number) != 'undefined') {
        this.activeBlock = elementsInViewArray.find((number) => number)
      }
    },
    next() {
      if (this.activePage > 0 && this.activePage < this.data.length) {
        this.$refs.flicking.next()
        this.activePage = this.$refs.flicking.index + 2
      }
    },
    prev() {
      if (this.activePage > 1) {
        this.$refs.flicking.prev()
        this.activePage = this.$refs.flicking.index
      }
    }
  },
  components: {
    Flicking: Flicking
  },
  data() {
    return {
      page: { window: 0, top: 0 },
      activeBlock: 'RiceIntake',
      activePage: 1,
      data: [
        {
          href: 'RiceIntake',
          title: 'processing.data-title-1',
          description: 'processing.data-description-1',
          image: require('@/assets/processing/1 rice-intake.jpeg')
        },
        {
          href: 'CleaningandDestoning',
          title: 'processing.data-title-2',
          description: 'processing.data-description-2',
          image: require('@/assets/processing/2 cleaning.jpeg')
        },
        {
          href: 'Polishing',
          title: 'processing.data-title-3',
          description: 'processing.data-description-3',
          image: require('@/assets/processing/3 polishing.jpeg')
        },
        {
          href: 'Grading',
          title: 'processing.data-title-4',
          description: 'processing.data-description-4',
          image: require('@/assets/processing/4 grading.jpeg')
        },
        {
          href: 'ColorSorting',
          title: 'processing.data-title-5',
          description: 'processing.data-description-5',
          image: require('@/assets/processing/5 color-storing.jpeg')
        },
        {
          href: 'LabTest',
          title: 'processing.data-title-6',
          description: 'processing.data-description-6',
          image: require('@/assets/processing/6 lab-test.jpeg')
        },
        {
          href: 'Packaging',
          title: 'processing.data-title-7',
          description: 'processing.data-description-7',
          image: require('@/assets/processing/7 packaging.jpeg')
        },
        {
          href: 'Storing',
          title: 'processing.data-title-8',
          description: 'processing.data-description-8',
          image: require('@/assets/processing/8 storing.jpeg')
        },
        {
          href: 'Exporting',
          title: 'processing.data-title-9',
          description: 'processing.data-description-9',
          image: require('@/assets/processing/10 export.jpeg')
        }
      ]
    }
  }
}
</script>

<style>
.green-toggle-inner-circle {
  fill: #97aa8a;
  transform: scale(0.4);
  transform-origin: 50% 50%;
  transition: 0.4s ease all;
}
.green-toggle-opacity {
  opacity: 0.3;
}
.green-toggle-opacity {
  opacity: 0.3;
}

.green-toggle-outer-circle {
  fill: #148273;
}

.green-toggle-inner-circle {
  fill: #f7ba2a;
  transform: scale(0.4);
  transform-origin: 50% 50%;
  transition: 0.4s ease all;
}

.green-toggle-outer-circle {
  transform-origin: 50% 50%;
  -webkit-animation: togglepulse 1.5s infinite;
  animation: togglepulse 1.5s infinite;
  transition: 2s ease all;
}
@-webkit-keyframes togglepulse {
  0% {
    opacity: 0.8;
    transform: scale(0.4);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes togglepulse {
  0% {
    opacity: 0.8;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}
</style>
