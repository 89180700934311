<template>
  <div>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-12 main">
          <Flicking
            ref="flicking"
            bound="{true}"
            :options="{
              align: 'prev',
              panelsPerView: 1,
              easing: (x) => x,
              inputType: ['mouse']
            }"
            :plugins="plugins"
            @move-start="(e) => moveEnd(e)"
          >
            <div class="panel" v-for="(value, index) in product" :key="index">
              <kinesis-container>
                <div class="bg-product">
                  <div class="row">
                    <div class="col-12 col-lg-6 text-center">
                      <div
                        class="product text-center animate__animated animate__fast"
                        :class="index + 1 == activePage ? bindClass : ''"
                      >
                        <div>
                          <img
                            :src="value.image"
                            class="mw-100 img-product"
                            alt=""
                          />
                        </div>

                        <div
                          data-parallaxify-background-range="100"
                          :class="` effect effect-${index + 1}`"
                        >
                          <kinesis-element :strength="50">
                            <img :src="value.effect" alt="" />
                          </kinesis-element>
                        </div>
                        <div
                          class="desc animate__animated animate__delay-1s"
                          :class="
                            index + 1 == activePage
                              ? `animate__fadeIn desc-${index + 1}`
                              : ` desc-${index + 1}`
                          "
                        >
                          <img :src="value.imgDesc" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="animate__animated animate__delay-0-4s"
                        :class="index + 1 == activePage ? bindClass : ''"
                      >
                        <h2 class="my-5">
                          {{ $t(value.title) }}
                        </h2>
                        <div v-html="$t(value.description)"></div>
                        <div style="margin-top: 25px">
                          <div
                            v-for="(data, num) in value.link"
                            :key="num"
                            class="d-flex"
                          >
                            <!-- <div class="">
                              <a :href="data.link" target="_blank">{{
                                $t('product.download')
                              }}</a>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </kinesis-container>
            </div>
          </Flicking>
          <div class="arrow-sl d-none d-lg-flex">
            <div
              class="arr-left mr-4"
              :class="{ lastColor: activePage == 1 }"
              @click="activePage > 1 ? prev() : ''"
            >
              <i class="fa-solid fa-chevron-left"></i>
            </div>
            <div
              class="arr-right"
              :class="{ lastColor: activePage == 4 }"
              @click="activePage < 4 ? next() : ''"
            >
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </div>
          <div class="pagination">
            <div class="page" v-for="(value, index) in product" :key="index">
              <div
                class="dot"
                @click="moveSlide(index)"
                :class="{ active: index + 1 == activePage }"
              ></div>
            </div>
          </div>
          <div class="see-product">
            <p class="mb-4 font-scroll">Scroll Down To See Product</p>
            <div class="mb-2">
              <img src="@/assets/mouse.png" alt="" />
            </div>
            <div>
              <img src="@/assets/arrow-down.png" alt="" />
            </div>
          </div>
          <div class="process-bar">
            <div class="processing" :style="`width:${process}%`"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <ProductMobile />
    </div>
    <li style="opacity: 0">
      <a href="#header" id="to-header" v-smooth-scroll>Processing</a>
    </li>
  </div>
</template>

<script>
import WheelIndicator from 'wheel-indicator'
import { Flicking } from '@egjs/vue-flicking'
import { Parallax } from '@egjs/flicking-plugins'
import ProductMobile from '@/components/ProductMobile'
export default {
  computed: {
    process() {
      return (this.activePage / this.product.length) * 100
    }
  },
  components: {
    Flicking,
    ProductMobile
  },

  data() {
    return {
      plugins: [new Parallax('img', 4)],
      indicator: null,
      activePage: 1,
      bindClass: 'animate__fadeInRight',

      product: [
        {
          link: [
            {
              title: '',
              link: '/document/Thai-Hom-Mali-White-Rice.pdf'
            }
          ],
          image: require('@/assets/product/1/product.png'),
          effect: require('@/assets/product/1/effect.png'),
          imgDesc: require('@/assets/product/1/description.png'),
          title: 'product.content-1.title',
          description: 'product.content-1.description'
        },
        {
          link: [
            {
              title: 'Thai White Rice 100% Grade A',
              link: '/document/Thai-White-Rice.pdf'
            }
          ],
          image: require('@/assets/product/2/product.png'),
          effect: require('@/assets/product/2/effect.png'),
          imgDesc: require('@/assets/product/2/description.png'),
          title: 'product.content-2.title',
          description: 'product.content-2.description'
        },
        {
          link: [
            {
              title: 'Thai Parboiled Rice',
              link: '/document/Thai-Parboiled-Rice.pdf'
            }
          ],
          image: require('@/assets/product/3/product.png'),
          effect: require('@/assets/product/3/effect.png'),
          imgDesc: require('@/assets/product/3/description.png'),
          title: 'product.content-3.title',
          description: 'product.content-3.description'
        },
        {
          link: [
            {
              title: 'Thai Glutinous Rice',
              link: '/document/Thai-White-Glutinous.pdf'
            }
          ],
          image: require('@/assets/product/4/product.png'),
          effect: require('@/assets/product/4/effect.png'),
          imgDesc: require('@/assets/product/4/description.png'),
          title: 'product.content-4.title',
          description: 'product.content-4.description'
        }
      ]
    }
  },
  methods: {
    moveSlide(e) {
      this.$refs.flicking.moveTo(e)

      this.activePage = e + 1
    },
    next() {
      console.log(this.activePage == this.product.length)
      if (this.device && this.activePage == this.product.length) {
        document.getElementById('to-about').click()
        console.log(document.getElementById('to-about'))
      }
      if (this.activePage > 0 && this.activePage < this.product.length) {
        this.$refs.flicking.next()
        this.activePage = this.$refs.flicking.index + 2
      }
    },
    prev() {
      console.log(this.activePage == this.product.length)
      if (this.activePage > 1) {
        this.$refs.flicking.prev()
        this.activePage = this.$refs.flicking.index
      } else {
        this.device ? document.getElementById('to-header').click() : null
      }
    },
    moveEnd(e) {
      console.log(e.currentTarget.index, e.direction)
      if (e.currentTarget.index > 0) {
        if (e.direction == 'NEXT') {
          this.activePage = e.currentTarget.index + 1
        } else {
          this.activePage = e.currentTarget.index + 1
        }
      }
    }
  },
  mounted() {
    console.log(screen.width)
    if (!this.indicator) {
      this.indicator = new WheelIndicator({
        elem: document.querySelector('.main'),
        callback: (e) => {
          if (e.direction == 'up') {
            // if (this.activeProd - 1 == -1) {
            //   this.activeProd = this.product.length - 1;
            // } else {
            //   this.activeProd--;
            // }
            this.bindClass = 'animate__fadeInLeft'
            this.prev()
          } else {
            // if (this.activeProd + 1 == this.product.length) {
            //   this.activeProd = 0;
            // } else {
            //   this.activeProd++;
            // }
            this.next()
            this.bindClass = 'animate__fadeInRight'
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.product {
  position: relative;
  .effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .effect-4 {
    left: 2px;
  }
  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .desc-1 {
    top: 20px;
    left: -33px;
  }
  .desc-2 {
    top: 47px;
    left: -74px;
  }
  .desc-3 {
    top: 39px;
    left: -128px;
  }
  .desc-4 {
    top: 25px;
    left: -180px;
  }
}
.arrow-sl {
  color: #fcfcfc;
  i {
    cursor: pointer;
  }
  // .arr-right,
  // .arr-left {
  //   color: rgba(252, 252, 252, 0.7);
  // }
  // .arr-right:hover,
  // .arr-left:hover {
  //   color: #fcfcfc;
  // }
  .arr-right,
  .arr-left {
    &.lastColor {
      color: #70a09b;
    }
  }
  position: absolute;
  left: 0;
  right: 50px;
  top: 40px;
  display: flex;
  justify-content: flex-end;

  font-size: 28px;
  z-index: 1;
}
.see-product {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  z-index: 1;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  /* identical to box height, or 0% */
  text-align: center;
  color: #c0d1cf;
}
.img-product {
  transform: translate(-0%) translate(0px) !important;
}
.animate__animated {
  &.animate__delay-0-4s {
    -webkit-animation-delay: calc(1s * 0.4);
    animation-delay: calc(1s * 12);
    -webkit-animation-delay: calc(var(--animate-delay) * 0.4);
    animation-delay: calc(var(--animate-delay) * 0.4);
  }

  &.animate__delay-1s {
    -webkit-animation-delay: calc(1s * 1.1);
    animation-delay: calc(1s * 12);
    -webkit-animation-delay: calc(var(--animate-delay) * 1.1);
    animation-delay: calc(var(--animate-delay) * 1.1);
  }
}
</style>
